import clsx from 'clsx';
import { Outlet, useNavigate } from 'react-router-dom';

import { BotaoComTexto } from '@digix-ui/componentes';
import logoClaro from 'assets/icons/logo-claro.svg';
import classes from './styles.module.scss';

export function AppLayoutPublico(): JSX.Element {
  const navigate = useNavigate();

  return (
    <>
      <header className="cabecalho-sistema">
        <div className="cabecalho-sistema__container-primario">
          <BotaoComTexto
            visual="sem-preenchimento"
            tipo="button"
            onClick={() => navigate(`/`, { replace: true })}
            classesAuxiliares={classes.botaoLogo}
          >
            <img
              className={clsx('cabecalho-sistema__logo', classes.icone)}
              width="33"
              src={logoClaro}
              alt="Logotipo do Atendimento"
            />
          </BotaoComTexto>
        </div>
      </header>
      <Outlet />
    </>
  );
}
