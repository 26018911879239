import { useCallback, useState } from 'react';

import { AxiosError } from 'axios';

import { ParametrosDoProvedorDeAutenticacao } from 'models/parametros-do-provedor-de-autenticacao';
import { useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';
import { UseProvedorDeAutenticacaoResposta } from './types';

export function useProvedorDeAutenticacao(): UseProvedorDeAutenticacaoResposta {
  const criarMensagem = useGerenciadorDeMensagens();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const obterParametros = useCallback(async () => {
    try {
      setLoading(true);

      const { data: result } =
        await servicoBase.get<ParametrosDoProvedorDeAutenticacao>(
          '/v1/provedorDeAutenticacao',
        );

      return result || null;
    } catch (error: any) {
      criarMensagem({
        titulo: 'Aconteceu um erro',
        texto:
          error?.response?.data?.detail ||
          'Ocorreu um erro ao obter parâmetros do autenticador.',
        tipo: 'erro',
      });
      setErro(error as AxiosError);
    } finally {
      setLoading(false);
    }

    return null;
  }, [criarMensagem]);

  return [obterParametros, { loading, erro }];
}
