import { useCallback, useEffect } from 'react';
import { fruitsConfig } from 'config/fruits';

import { useAuth } from './use-auth';

declare global {
  interface Window {
    $productFruits?: any[];
  }
}

export const useFruits = (): void => {
  const { login } = useAuth();

  const incluirFruitsScript = useCallback(() => {
    if (!fruitsConfig.fruitsId) return;
    if (!login) return;

    if (!window.$productFruits) return;

    if (
      process.env.REACT_APP_NODE_ENV !== 'production' &&
      process.env.REACT_APP_NODE_ENV !== 'homologation'
    )
      return;

    window.$productFruits.push([
      'init',
      fruitsConfig.fruitsId,
      'pt',
      { username: login },
    ]);
  }, [login]);

  useEffect(() => {
    incluirFruitsScript();
  }, [incluirFruitsScript]);
};
