import { useCallback, useState } from 'react';

import { AxiosError } from 'axios';

import { RecuperarSenha } from 'models';
import { useGerenciadorDeMensagens, useQuery } from 'hooks';
import { servicoBase } from 'services/servico-base';
import { UseRecuperarSenhaResposta } from './types';

export function useRecuperarSenha(): UseRecuperarSenhaResposta {
  const criarMensagem = useGerenciadorDeMensagens();
  const ehRedefinir = useQuery('redefinir');
  const tituloSucesso = ehRedefinir
    ? 'Senha redefinida com sucesso'
    : 'Senha definida com sucesso';
  const tituloErro = ehRedefinir
    ? 'Erro ao redefinir a senha'
    : 'Erro ao definir a senha';
  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const recuperarSenha = useCallback(async (campos: RecuperarSenha) => {
    try {
      setLoading(true);

      const { data: result } = await servicoBase.post(
        '/v1/autenticacoes/recuperarsenha',
        campos,
      );

      if (result)
        criarMensagem({
          titulo: tituloSucesso,
          texto: '',
          tipo: 'sucesso',
        });

      return result || null;
    } catch (error: any) {
      const descricaoDoErro =
        error?.response?.data?.detail || 'Tente novamente mais tarde.';
      criarMensagem({
        titulo: tituloErro,
        texto: descricaoDoErro,
        tipo: 'erro',
      });
      setErro(error as AxiosError);
    } finally {
      setLoading(false);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [recuperarSenha, { loading, erro }];
}
