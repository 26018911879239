/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfigItem } from 'models';

function generateRouteID(key: string): string {
  const prefix = process.env.REACT_APP_STORAGE_PREFIX || '@horus-digix';
  return `${prefix}:${key}`;
}

export const routesConfig: RouteConfigItem[] = [
  {
    id: generateRouteID('home'),
    path: '/',
    elementPath: 'Home',
    isPrivate: false,
    hasExternalAuthentication: false,
    redirectPath: '/app/documentos',
  },
  {
    id: generateRouteID('publico'),
    path: '/publico',
    elementPath: 'DocumentosPublicos',
    isPrivate: false,
    hasExternalAuthentication: false,
    redirectPath: '/publico/documentos',
    children: [
      {
        id: generateRouteID('consulta-de-documento'),
        path: '/documentos',
        elementPath: 'DocumentosPublicos',
        routeTitle: 'Consulta de documento público',
        isPrivate: false,
        hasExternalAuthentication: false,
        redirectPath: '/',
      },
      {
        id: generateRouteID('documento'),
        path: '/documentos/:token',
        elementPath: 'ArquivoPublico',
        routeTitle: 'Consulta Pública',
        isPrivate: false,
        hasExternalAuthentication: true,
        redirectPath: '/',
      },
    ],
  },
  {
    id: generateRouteID('app'),
    path: '/app',
    isPrivate: true,
    hasExternalAuthentication: false,
    children: [
      {
        id: generateRouteID('documentos'),
        path: '/documentos',
        elementPath: 'Documentos',
        routeTitle: 'Documentos',
        isPrivate: true,
        hasExternalAuthentication: false,
        redirectPath: '/',
      },
      {
        id: generateRouteID('arquivo'),
        path: '/arquivo/:id',
        elementPath: 'Arquivo',
        routeTitle: 'Arquivo',
        isPrivate: true,
        hasExternalAuthentication: false,
        redirectPath: '/',
      },
      {
        id: generateRouteID('configuracoes'),
        path: '/configuracoes',
        elementPath: 'Configuracoes',
        routeTitle: 'Configurações',
        isPrivate: true,
        hasExternalAuthentication: false,
        redirectPath: '/',
        permissions: ['admin'],
      },
    ],
  },
];
